import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;
// const headers = {
//   "ngrok-skip-browser-warning": "true",
// };
const headers = {
  "Content-Type": "application/json",
};

// ..........GET PRODUCT API ................
export async function GetProductAPI(customerId) {
  try {
    const response = await axios.get(`${url}/all-products?customerId=${customerId}`, {
      headers: headers
    });
    const data = response.data;
    return data.data;
  } catch (error) {
    console.log("Error fetching products:", error.message);
  }
}

// ............GET SINGLE PRODUCT API...............
export async function GetSingleProductAPI(id, userId) {
  try {
    const apiUrl = userId
      ? `${url}/similar-products/${id}?customerId=${userId}`
      : `${url}/similar-products/${id}`;

    const response = await axios.get(apiUrl, {
      headers: headers,
    });
    if (!response) {
      throw new Error("Failed to fetch product");
    }
    return response;
  } catch (error) {
    console.error("Error fetching product:", error);
    throw error;
  }
}
// .........CREATE ORDER API ..............
export async function CreateOrderAPI({ orderData }) {
  try {
    const response = await axios.post(
      `http://137.184.62.50:8001/order-create`,
      { orderData },
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error sending data:", error);
    throw error;
  }
}
// ...............Latest product API.............
export async function LatestProductAPI(customerId) {
  try {
    const response = await axios.get(`${url}/products/latest?customerId=${customerId}`, {
      headers: headers,
    });
    if (!response) {
      throw new Error("Failed to fetch product");
    }
    return response;
  } catch (error) {
    console.error("Error fetching product:", error.message);
  }
}
// ................Email Subscribe API..............
export async function EmailSubscribeApi(data) {
  try {
    const response = await axios.post(
      `${url}/customer/add-subscribe-email`,
      data
    );
    return response;
  } catch (error) {
    console.error("Error  to Subscribe Email Api:", error);
    throw error;
  }
}
// ..................Contact Form API................
export async function ContactFormApi(data) {
  try {
    const response = await axios.post(`${url}/contact-form`, data);
    return response;
  } catch (error) {
    console.log(`Error to save data in contact form`, error);
    throw error;
  }
}
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
export async function GetPdf() {
  try {
    const response = await axios.get(`${url}/Get-PDF`);
    return response.data;
  } catch (error) {
    console.log(error, "error");
    return error;
  }
}
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
export const CancelOrder = async (data) => {
  try {
    const respone = await axios.post(`${url}/order-cancel`, data);
    return respone;
  } catch (error) {
    return error;
  }
};
