import React, { useEffect, useState } from "react";
import "./common.css";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { GetProductAPI } from "../../APIComponents/ProductAPIs/GetAPI";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { AddToCart, getCustomerId } from "../../APIComponents/CartsApi";
import { Toaster, toast } from "react-hot-toast";
import { useCart } from "../../PopupComponent/CartContext";
import CartTabPopup from "../../PopupComponent/CartTabPopup";

export default function LatestProduct() {
  const { updateCartCount } = useCart();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [isCartPopupOpen, setCartPopupOpen] = useState(false);
  const [cartItem, setCartItem] = useState(null);
  const [customerId, setCustomerId] = useState(null);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
    prevArrow: <></>,
    nextArrow: <></>,
  };

  useEffect(() => {
    const initializeIdentifier = async () => {
      try {
        const storedUserId = localStorage.getItem('userId');
        console.log(storedUserId, "storeduserId")
        if (storedUserId) {
          setCustomerId(storedUserId);
        } else {
          const customerIdFromStorage = localStorage.getItem("customerId");
          console.log(customerIdFromStorage, "stored customer id")
          if (customerIdFromStorage) {
            setCustomerId(customerIdFromStorage);
          } else {
            const fetchedCustomerId = await getCustomerId();
            localStorage.setItem("customerId", fetchedCustomerId);
            setCustomerId(fetchedCustomerId);
          }
        }
      } catch (error) {
        console.error("Error initializing customer ID:", error);
      }
    };

    initializeIdentifier();
  }, []);

  useEffect(() => {
    const getProduct = async () => {
      try {
        if (customerId) {
          const productData = await GetProductAPI(customerId);
          const filteredProducts = productData.filter(product => {
            const stock = Number(product.productStock) || 0;
            const isActive = product.productActive === true;

            if (stock === 0 && !isActive) {
              return false;
            }
            return true;
          });
          setProducts(filteredProducts);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (customerId) {
      getProduct();
    }
  }, [customerId]);

  const ShowAllProducts = () => {
    navigate("/all-products?latest=true");
  };

  const handleAddToCart = async (productId) => {
    try {
      let currentCustomerId = customerId;

      if (!currentCustomerId) {
        currentCustomerId = await getCustomerId();
        localStorage.setItem("customerId", currentCustomerId);
        setCustomerId(currentCustomerId);
      }

      console.log("Customer ID used:", currentCustomerId);
      const response = await AddToCart(currentCustomerId, productId, 1);
      console.log("API Response:", response);
      if (response && response.status === 200) {
        toast.success(response.message || "Product is Added to Cart");
        updateCartCount();
        setCartItem(productId);
        setCartPopupOpen(true);
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      toast.error("Error adding product to cart");
    }
  };

  const viewProduct = (productId) => {
    navigate(`/viewSingleProduct/${productId}`);
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="product-list-home">
        <div className="container">
          <div className="latest-product-area mt-5">
            <div className="latest-header">
              <h3 className="latest-heading">{t("Our Latest Products")}</h3>
            </div>
            <div className="latest-product-box row slider-container">
              <Slider {...settings}>
                {products?.map((product) => (
                  <div className="col-md-3 col-3" key={product._id}>
                    <div className="latest-product-co">
                      <div className="product-box">
                        <img
                          src={product.productImage}
                          alt={product.productTitle}
                          onClick={() => viewProduct(product._id)}
                          style={{ maxWidth: "68%", height: "170px" }}
                        />
                        <h4 className="latest-product-title">
                          {t(product.productTitle)}
                        </h4>
                        <p className="price-product">
                          ${t(product.productPrice)}
                        </p>
                        <button
                          className="btn btn-primary"
                          onClick={() => handleAddToCart(product._id)}
                          disabled={product.productStockAvailable <= 0}
                        >
                          {product.productStockAvailable <= 0
                            ? t("Out of Stock")
                            : t("Add to Cart")}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div className="shop-button mt-4 text-center">
              <button
                type="button"
                className="btn btn-primary"
                onClick={ShowAllProducts}
              >
                {t("Show All")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {isCartPopupOpen && (
        <CartTabPopup
          open={isCartPopupOpen}
          onClose={() => setCartPopupOpen(false)}
        />
      )}
    </>
  );
}
