import React, { useState, useEffect } from "react";
import "./Checkout.css";
import CheckoutData from "./CheckoutData";
import { getCart, getCustomerId } from "../../../../APIComponents/CartsApi";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import {
  CreateOrderAPI,
  EmailSubscribeApi,
} from "../../../../APIComponents/ProductAPIs/GetAPI";
import { useLocation } from "react-router-dom";
import { useCart } from "../../../../PopupComponent/CartContext";
export default function CheckoutNew() {
  // ...............>>>>>>>>>>>>>>>>>>>>>>>>>>>>,..........................
  const Navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState("");
  const [billingAddress, setBillingAddress] = useState("sameAsShipping");
  const [selectedCountry, setSelectedCountry] = useState("country");
  const [selectedState, setSelectedState] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [saveInfo, setSaveInfo] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    address: "",
    addressOptional: "",
    city: "",
    postalCode: "",
    billingFirstName: "",
    billingCompany: "",
    billingLastName: "",
    billingAddress: "",
    billingAddressOptional: "",
    billingCity: "",
    billingPostalCode: "",
    billingCountry: "",
    billingState: "",
  });
  const location = useLocation();
  const { productId, quantity } = location.state || {};
  const { updateCartCount } = useCart();
  //....................>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>..............................
  useEffect(() => {
    const savedData = localStorage.getItem("formData");
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);
  // ...................>>>>>>>>>>>>>>>>>>>>>>>>>..............................
  useEffect(() => {
    const fetchCustomerId = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const id =  userId ? userId : getCustomerId();
        // const id = await getCustomerId();
        setCustomerId(id);
      } catch (error) {
        console.error("Failed to fetch customer ID:", error);
        setError("Failed to fetch customer ID");
      }
    };
    fetchCustomerId();
  }, []);
  // .......................>>>>>>>>>>>>>>>>>>>>..............................
  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        if (customerId) {
          const response = await getCart(customerId);
          const items = response.data;
          if (Array.isArray(items)) {
            const cartData = items.map((item) => ({
              productId: item.ProductId,
              quantity: item.Quantity,
              totalAmount: item?.productDetails?.productPrice,
            }));
            setCartItems(cartData);
          } else {
            console.error("Fetched data is not an array:", items);
          }
        }
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch cart items:", error);
        setError("Failed to fetch cart items");
        setLoading(false);
      }
    };
    fetchCartItems();
  }, [customerId]);
  // .......................>>>>>>>>>>>>>>>>>>>>..............................
  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };
  const handleStateChange = (value) => {
    setSelectedState(value);
  };
  // .......................>>>>>>>>>>>>>>>>>>>............................
  const handlebillingCountryChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      billingCountry: value,
    }));
  };
  // ..................>>>>>>>>>>>>>>>>>>>>>>>>>>..........................
  const handleSaveInfo = (e) => {
    setSaveInfo(e.target.checked);
  };
  // ....................>>>>>>>>>>>>>>>>>>>............................
  const subscribe = async () => {
    if (!email) {
      toast.error("Please enter your email address.");
      return;
    }
    try {
      const response = await EmailSubscribeApi({ email });
      console.log("Subscribe API Response:", response);
      toast.success("Subscription successful!");
    } catch (error) {
      console.error("Error subscribing:", error);
      toast.error("Subscription failed. Please try again later.");
    }
  };
  // ....................>>>>>>>>>>>>>>>>>>>>>>>>>>.........................
  const handlebillingStatechange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      billingState: value,
    }));
  };
  // ..................>>>>>>>>>>>>>>>>>>>>>>>>..............................
  // const handleInputChange = (e) => {
  //   const { id, value } = e.target;
  //   setInputValue(value);
  //   const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  //   const isMobile = /^[0-9]{10}$/.test(value);
  //   if (isEmail) {
  //     setEmail(value);
  //     setMobile("");
  //   } else if (isMobile) {
  //     setMobile(value);
  //     setEmail("");
  //   } else {
  //     setFormErrors({ emailOrMobile: "Invalid email or mobile number" });
  //   }
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [id]: value,
  //   }));
  //   setFormErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [id]: "",
  //   }));
  // };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    // Handle email or mobile input separately
    if (id === "emailOrMobile") {
      const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      const isMobile = /^[0-9]{10}$/.test(value);
      if (isEmail) {
        setEmail(value);
        setMobile("");
      } else if (isMobile) {
        setMobile(value);
        setEmail("");
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          emailOrMobile: "Invalid email or mobile number",
        }));
      }
      setInputValue(value); // Update the state for emailOrMobile input field
    }
    // Update the general form data state for other fields
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    // Clear specific error for the field being updated
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
  };
  // ..................>>>>>>>>>>>>>>>>>>>>>>>>..................................
  const validateForm = () => {
    const errors = {};
    const requiredFields = [
      "firstName",
      "lastName",
      "address",
      "city",
      "postalCode",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = `${field
          .replace(/([A-Z])/g, " $1")
          .toLowerCase()} is required`;
      }
    });
    if (!selectedCountry) errors.country = "Country is required";
    if (!selectedState) errors.state = "State is required";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // .......................>>>>>>>>>>>>>>>>>>>>>>>..................................
  const handleTotalAmountChange = (newTotal) => {
    setTotalAmount(newTotal);
  };
  // ...........................>>>>>>>>>>>>>>>>>>>>>>>>>..............................
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!paymentMethod) {
      toast.error("Please select a payment method.");
      return;
    }
    if (!validateForm()) return;

    if (!customerId) {
      console.error("Customer ID is not available");
      return;
    }
    if (saveInfo) {
      localStorage.setItem("formData", JSON.stringify(formData));
    }
    
    const orderData = {
      Email: email,
      Mobile: mobile,
      paymentMethod: paymentMethod,
      cartItems: cartItems,
      customerData: {
        customerId: customerId,
        firstName: formData.firstName,
        lastName: formData.lastName,
        totalAmount: totalAmount,
      },
      shippingAddressDetails: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        company: formData.billingCompany,
        address: formData.address,
        addressOptional: formData.addressOptional,
        city: formData.city,
        state: selectedState,
        country: selectedCountry,
        zipCode: formData.postalCode,
      },
      billingAddressDetails:
        billingAddress === "sameAsShipping"
          ? {
              firstName: formData.firstName,
              lastName: formData.lastName,
              company: formData.company,
              address: formData.address,
              addressOptional: formData.addressOptional,
              city: formData.city,
              state: selectedState,
              country: selectedCountry,
              zipCode: formData.postalCode,
            }
          : {
              firstName: formData.billingFirstName,
              lastName: formData.billingLastName,
              company: formData.billingCompany,
              address: formData.billingAddress,
              addressOptional: formData.billingAddressOptional,
              city: formData.billingCity,
              state: formData.billingState,
              country: formData.billingCountry,
              zipCode: formData.billingPostalCode,
            },
    };

    if (productId && quantity) {
      orderData.productId = productId;
      orderData.quantity = quantity;
      orderData.cartItems = [
        {
          productId: productId,
          quantity: quantity,
        },
      ];
    } else {
      orderData.cartItems = cartItems;
    }
    try {
      const response = await CreateOrderAPI({ orderData });
      console.log(response, "frontned response");
      if (response && response.success === true) {
        Navigate("/Thankyou");
        await updateCartCount();
        setFormData({
          firstName: "",
          lastName: "",
          address: "",
          addressOptional: "",
          city: "",
          postalCode: "",
          billingFirstName: "",
          billingLastName: "",
          billingCompany: "",
          billingAddress: "",
          billingAddressOptional: "",
          billingCity: "",
          billingPostalCode: "",
        });
        setCartItems([]);
        setPaymentMethod("");
        setBillingAddress("sameAsShipping");
        setSelectedCountry(null);
        setSelectedState(null);
      } else {
        console.error("Order creation failed:", response.message);
      }
    } catch (error) {
      console.error("Error saving order:", error);
    }
  };
  // ..................................>>>>>>>>>>>>>>>>>>>>>>>...................................
  const handleSubscriptionChange = (e) => {
    setIsSubscribed(e.target.checked);
    if (e.target.checked) {
      const emailInput = document.getElementById("emailOrMobile").value;
      if (emailInput.includes("@")) {
        subscribe();
      }
    }
  };
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="checkout-outer checkout-main-ms">
        <div className="container">
          <div className="row checkout-form-main">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 checkout-left">
              <h2>Contact</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className={`form-control ${
                      formErrors.emailOrMobile ? "is-invalid" : ""
                    }`}
                    id="emailOrMobile"
                    placeholder="Email or mobile phone number"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                  {formErrors.emailOrMobile && (
                    <div className="invalid-feedback">
                      {formErrors.emailOrMobile}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="checkbox"
                    id="newsletter"
                    name="newsletter"
                    checked={isSubscribed}
                    onChange={handleSubscriptionChange}
                  />
                  <label htmlFor="newsletter" style={{ display: "inline" }}>
                    Email me with news and offers
                  </label>
                </div>
                <div className="delivery-title">
                  <h2>Delivery</h2>
                </div>
                <div className="mb-3">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={selectedCountry || ""}
                    onChange={(e) => handleCountryChange(e.target.value)}
                  >
                    <option value="">Please Select a country</option>
                    <option value="Canada">Canada</option>
                  </select>
                  {formErrors.country && (
                    <div className="invalid-feedback">{formErrors.country}</div>
                  )}
                </div>
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <div className="mb-3">
                      <input
                        type="text"
                        className={`form-control ${
                          formErrors.firstName ? "is-invalid" : ""
                        }`}
                        id="firstName"
                        placeholder="First name (optional)"
                        onChange={handleInputChange}
                        value={formData.firstName}
                      />
                      {formErrors.firstName && (
                        <div className="invalid-feedback">
                          {formErrors.firstName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <div className="mb-3">
                      <input
                        type="text"
                        className={`form-control ${
                          formErrors.lastName ? "is-invalid" : ""
                        }`}
                        id="lastName"
                        placeholder="Last name"
                        onChange={handleInputChange}
                        value={formData.lastName}
                      />
                      {formErrors.lastName && (
                        <div className="invalid-feedback">
                          {formErrors.lastName}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className={`form-control ${
                      formErrors.address ? "is-invalid" : ""
                    }`}
                    id="address"
                    placeholder="Address"
                    onChange={handleInputChange}
                    value={formData.address}
                  />
                  {formErrors.address && (
                    <div className="invalid-feedback">{formErrors.address}</div>
                  )}
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className={`form-control ${
                      formErrors.addressOptional ? "is-invalid" : ""
                    }`}
                    id="addressOptional"
                    placeholder="Apartment, suite, etc. (optional)"
                    onChange={handleInputChange}
                    value={formData.addressOptional}
                  />
                </div>
                <div class="col">
                  <div class="mb-3">
                    <div style={{ position: "relative" }}>
                      <div class="stdropdown-container">
                        <div class="row">
                          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 py-0 city-one">
                            <input
                              type="text"
                              className={`form-control ${
                                formErrors.city ? "is-invalid" : ""
                              }`}
                              id="city"
                              placeholder="City"
                              onChange={handleInputChange}
                              value={formData.city}
                            />
                            {formErrors.city && (
                              <div className="invalid-feedback">
                                {formErrors.city}
                              </div>
                            )}
                          </div>
                          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 ">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              value={selectedState || ""}
                              onChange={(e) =>
                                handleStateChange(e.target.value)
                              }
                            >
                              <option value="">
                                Select Province or Territory
                              </option>
                              <option value="AB">Alberta</option>
                              <option value="BC">British Columbia</option>
                              <option value="MB">Manitoba</option>
                              <option value="NB">New Brunswick</option>
                              <option value="NL">
                                Newfoundland and Labrador
                              </option>
                              <option value="NS">Nova Scotia</option>
                              <option value="NT">Northwest Territories</option>
                              <option value="NU">Nunavut</option>
                              <option value="ON">Ontario</option>
                              <option value="PE">Prince Edward Island</option>
                              <option value="QC">Quebec</option>
                              <option value="SK">Saskatchewan</option>
                              <option value="YT">Yukon</option>
                            </select>
                            {formErrors.state && (
                              <div className="invalid-feedback">
                                {formErrors.state}
                              </div>
                            )}
                          </div>
                          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 postal-code-one">
                            <input
                              type="text"
                              className={`form-control ${
                                formErrors.postalCode ? "is-invalid" : ""
                              }`}
                              id="postalCode"
                              placeholder="Postal code"
                              onChange={handleInputChange}
                              value={formData.postalCode}
                            />
                            {formErrors.postalCode && (
                              <div className="invalid-feedback">
                                {formErrors.postalCode}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <input
                          type="checkbox"
                          id="saveInfo"
                          name="saveInfo"
                          checked={saveInfo}
                          onChange={handleSaveInfo}
                        />
                        <label for="saveInfo" style={{ display: "inline" }}>
                          Save This information for Next Time
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <h3 className="method-title">Shipping method</h3>
                  <div className="shiping-method">
                    <p>
                      Enter your shipping address to view available shipping
                      methods.
                    </p>
                  </div>
                </div>
                <div className="col payment-btm">
                  <h2>Payment</h2>
                  <p>All transactions are secure and encrypted.</p>
                  <div className="form-check-cod">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="paymentMethod"
                      id="cod"
                      value="cod"
                      checked={paymentMethod === "cod"}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="cod">
                      Cash on Delivery (COD)
                    </label>
                  </div>
                  {paymentMethod === "Cash" && (
                    <div className="method-content">
                      <p id="message" className="method-content">
                        Please speak with your Account Manager to schedule order
                        pickup and payment details.
                      </p>
                    </div>
                  )}
                
                </div>
                <div className="col billing-add">
                  <h3 className="billing-add">Billing address</h3>
                  <div className="form-check-cod-active">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="billingAddress"
                      id="sameAsShipping"
                      value="sameAsShipping"
                      checked={billingAddress === "sameAsShipping"}
                      onChange={(e) => setBillingAddress(e.target.value)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="sameAsShipping"
                    >
                      Same as shipping address
                    </label>
                  </div>
                  <div className="form-check-code">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="billingAddress"
                      id="differentAddress"
                      value="differentAddress"
                      checked={billingAddress === "differentAddress"}
                      onChange={(e) => setBillingAddress(e.target.value)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="differentAddress"
                    >
                      Use a different billing address
                    </label>
                  </div>
                  {billingAddress === "differentAddress" && (
                    <div className="form-check-billing-bl">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={formData.billingCountry || ""}
                        onChange={(e) =>
                          handlebillingCountryChange(e.target.value)
                        }
                      >
                        <option value="">Please Select a country</option>
                        <option value="Canada">Canada</option>
                      </select>
                      {formErrors.country && (
                        <div className="invalid-feedback">
                          {formErrors.country}
                        </div>
                      )}
                      <div className="row">
                        <div className="row order-pickup-name">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 user-detail">
                            <div className="mb-3">
                              <input
                                type="text"
                                className={`form-control ${
                                  formErrors.billingFirstName
                                    ? "is-invalid"
                                    : ""
                                }`}
                                id="billingFirstName"
                                placeholder="First name (optional)"
                                onChange={handleInputChange}
                                value={formData.billingFirstName}
                              />
                              {formErrors.billingFirstName && (
                                <div className="invalid-feedback">
                                  {formErrors.billingFirstName}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 order-pickup-name">
                            <div className="mb-3">
                              <input
                                type="text"
                                className={`form-control ${
                                  formErrors.billingLastName ? "is-invalid" : ""
                                }`}
                                id="billingLastName"
                                placeholder="Last name"
                                onChange={handleInputChange}
                                value={formData.billingLastName}
                              />
                              {formErrors.billingLastName && (
                                <div className="invalid-feedback">
                                  {formErrors.billingLastName}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="mb-3 pickup-name">
                            <input
                              type="text"
                              className="form-control"
                              id="billingCompany"
                              placeholder="Company (optional)"
                              onChange={handleInputChange}
                              value={formData.billingCompany}
                            />
                          </div>
                          <div className="mb-3 pickup-name">
                            <input
                              type="text"
                              className={`form-control ${
                                formErrors.billingAddress ? "is-invalid" : ""
                              }`}
                              id="billingAddress"
                              placeholder="Address"
                              onChange={handleInputChange}
                              value={formData.billingAddress}
                            />
                            {formErrors.billingAddress && (
                              <div className="invalid-feedback">
                                {formErrors.billingAddress}
                              </div>
                            )}
                          </div>
                          <div className="mb-3 pickup-name">
                            <input
                              type="text"
                              className="form-control"
                              id="billingAddressOptional"
                              placeholder="Apartment, suite, etc. (optional)"
                              onChange={handleInputChange}
                              value={formData.billingAddressOptional}
                            />
                          </div>
                        </div>
                        <div className="row city-main">
                          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 city-opt">
                            <div className="mb-3 pickup-name">
                              <input
                                type="text"
                                className={`form-control ${
                                  formErrors.billingCity ? "is-invalid" : ""
                                }`}
                                id="billingCity"
                                placeholder="City"
                                onChange={handleInputChange}
                                value={formData.billingCity}
                              />
                              {formErrors.billingCity && (
                                <div className="invalid-feedback">
                                  {formErrors.billingCity}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 city-opt">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              value={formData.billingState || ""}
                              onChange={(e) =>
                                handlebillingStatechange(e.target.value)
                              }
                            >
                              <option value="">
                                Select Province or Territory
                              </option>
                              <option value="AB">Alberta</option>
                              <option value="BC">British Columbia</option>
                              <option value="MB">Manitoba</option>
                              <option value="NB">New Brunswick</option>
                              <option value="NL">
                                Newfoundland and Labrador
                              </option>
                              <option value="NS">Nova Scotia</option>
                              <option value="NT">Northwest Territories</option>
                              <option value="NU">Nunavut</option>
                              <option value="ON">Ontario</option>
                              <option value="PE">Prince Edward Island</option>
                              <option value="QC">Quebec</option>
                              <option value="SK">Saskatchewan</option>
                              <option value="YT">Yukon</option>
                            </select>
                            {formErrors.state && (
                              <div className="invalid-feedback">
                                {formErrors.state}
                              </div>
                            )}
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 city-opt-last">
                            <div className="mb-3 pickup-name">
                              <input
                                type="text"
                                className={`form-control ${
                                  formErrors.billingPostalCode
                                    ? "is-invalid"
                                    : ""
                                }`}
                                id="billingPostalCode"
                                placeholder="Postal Code"
                                onChange={handleInputChange}
                                value={formData.billingPostalCode}
                              />
                              {formErrors.billingPostalCode && (
                                <div className="invalid-feedback">
                                  {formErrors.billingPostalCode}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="complete-order-btn">
                    <button type="submit" className="btn btn-primary">
                      Complete order
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 right-checkout">
              <CheckoutData
                key={productId}
                onTotalAmountChange={handleTotalAmountChange}
                productId={productId}
                quantity={quantity}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
