
import React, { useState, useEffect } from "react";
import { GetAllOrder } from "../customerDashboardApi/PostApi";
import CustomerProfile from "./CustomerProfile";
import profileIcon from "../../assets/img/profile-ic.png";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { getCustomerId } from "../../CustomerDashboard/APIComponents/CartsApi";

export default function OrderList() {
  const [orders, setOrders] = useState([]);
  const [customerId, setCustomerId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(4);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const userToken = localStorage.getItem("userToken");
  const userId = localStorage.getItem("userId");
  const Navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("customerId");
    Navigate("/");
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const initializeIdentifier = async () => {
      try {
        const storedCustomerId = localStorage.getItem("userId");
        if (storedCustomerId) {
          setCustomerId(storedCustomerId);
        } else {
          const customerIdFromApi = await getCustomerId();
          localStorage.setItem("customerId", customerIdFromApi);
          setCustomerId(customerIdFromApi);
        }
      } catch (error) {
        console.error("Error initializing customer ID:", error);
      }
    };

    initializeIdentifier();
  }, []);

  useEffect(() => {
    if (customerId) {
      const fetchOrders = async () => {
        try {
          const response = await GetAllOrder(customerId);
          if (response?.filteredOrders) {
            setOrders(response.filteredOrders);
          } else {
            console.error("Invalid response format:", response);
          }
        } catch (error) {
          console.error("Error fetching orders:", error);
        }
      };

      fetchOrders();
    }
  }, [customerId]);

  const pageCount = Math.ceil(orders.length / itemsPerPage);

  const handlePageChange = (event) => {
    setCurrentPage(event.selected);
  };

  const displayedOrders = orders.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <>
      <CustomerProfile />
      <section className="home">
        <div className="main-header-tag-title">
          <div className="Side-bar-center">Customer Dashboard</div>
          <div className="profile-icon">
            <img
              src={profileIcon}
              className="person-image"
              alt="profile-icon"
              onClick={toggleDropdown}
              style={{ cursor: "pointer" }}
            />
            {isDropdownOpen && userToken && (
              <div className="dropdown-menu">
                <button className="logout-button" onClick={handleLogout}>
                  <FiLogOut />
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
      {orders.length > 0 ? (
        <>
          <table
            className="table table-striped"
            style={{ width: "70%", marginLeft: "20%", marginTop: "10%" }}
          >
            <thead>
              <tr>
                <th scope="col">Product Image</th>
                <th scope="col">Title</th>
                <th scope="col">Delivery Status</th>
                <th scope="col">Product ID</th>
                <th scope="col">Product Price</th>
              </tr>
            </thead>
            <tbody>
              {displayedOrders.map((order) =>
                order.productDetails && order.productDetails.length > 0 ? (
                  <tr key={order._id}>
                    <td>
                      <Link to={`/admin/orderDetails?orderId=${order._id}`}>
                        {order.productDetails[0]?.productInfo?.productImage ? (
                          <img
                            src={
                              order.productDetails[0].productInfo.productImage
                            }
                            alt={
                              order.productDetails[0].productInfo.productTitle
                            }
                            style={{ height: "100px", width: "50%" }}
                            className="order-img"
                          />
                        ) : (
                          <img
                            // src="path/to/placeholder-image.jpg"
                            alt="Image"
                            style={{ height: "100px", width: "50%" }}
                            className="order-img"
                          />
                        )}
                      </Link>
                    </td>
                    <td>
                      {order.productDetails[0]?.productInfo?.productTitle ||
                        "N/A"}
                    </td>
                    <td>{order.deliveryStatus || "N/A"}</td>
                    <td>
                      {order.productDetails[0]?.orderedProductId || "N/A"}
                    </td>
                    <td>
                      ${order.productDetails[0]?.productInfo?.productPrice ||
                        "N/A"}
                    </td>
                  </tr>
                ) : (
                  <tr key={order._id}>
                    <td colSpan="5">No products available</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          <div style={{ marginLeft: "40%" }}>
            {pageCount > 0 && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            )}
          </div>
        </>
      ) : (
        <div style={{ marginLeft: "20%", marginTop: "10%" }}>
          <p>No orders found</p>
        </div>
      )}
    </>
  );
}
