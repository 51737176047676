import axios from "axios";
const URL = process.env.REACT_APP_BASE_URL;
// const URL = "http://localhost:8001";
// const headers = {
//   "Content-type": "application/json",
// };
const headers = {
  "ngrok-skip-browser-warning": "true",
};
// .......................Registration......................
export const CustomerRegister = async (data) => {
  try {
    const response = await axios.post(`${URL}/register`, data, {
      headers: headers,
    });
    console.log(response,"backend order response")
    return response.data;
  } catch (error) {
    console.error("Error while registering customer:", error);
    throw error;
  }
};
// .......................Verify Otp........................
export const VerifyOtp = async (data) => {
  const { email, OTP } = data;

  try {
    const response = await axios.post(
      `${URL}/verify-OTP?email=${email}`,
      { OTP },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error while verifying OTP:", error);
    throw error;
  }
};
// .........................Resend OTP............................
export const ResendOtp = async (email) => {
  try {
    const response = await axios.put(
      `${URL}/customer/resend-otp?email=${email}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
// ........................Confirm Password API.....................
export const confirmPassword = async ({ email, password }) => {
  try {
    const response = await axios.post(
      `${URL}/customer/add-password?email=${email}`,
      { password }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
// ....................Customer Login API.................
export const CustomerLoginApi = async (data) => {
  try {
    
    const response = await axios.post(`${URL}/customer/login`, data, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
// .................Get customer profile data .................
export const CustomerProfileAPi = async (email) => {
  try {
    const response = await axios.get(
      `${URL}/get-profile-data?email=${email}`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
// ...............customer forgot password api..............
export const ForgotPasswords = async (
  email,
  resetCode,
  newPassword,
  confirmPassword
) => {
  try {
    const response = await axios.put(
      `${URL}/customer/reset-password?email=${email}`,
      {
        resetCode,
        newPassword,
        confirmPassword,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
// ..............customer-reset-email..................
export const ForgotPasswordEmail = async (email) => {
  try {
    const response = await axios.post(
      `${URL}/customer/request-password-reset`,
      { email }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
// ...........update Customer Profile Data................
export const UpdateCustomer = async (form, email) => {
  try {
    const formData = new FormData();
    formData.append("name", form.name);
    formData.append("email", form.email);
    formData.append("image", form.image);
    formData.append("address", form.address);
    formData.append("phoneNumber", form.phoneNumber);
    const response = await axios.put(
      `${URL}/customer/edit-profile?email=${encodeURIComponent(email)}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
// ......................Get All Order API...........................
export const GetAllOrder = async (customerId) => {
  try {
     console.log(customerId,"order cusotmer id backedn")
    const response = await axios.get(
      `${URL}/get/customer/orderList/${customerId}`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// ...............single Order Details.......................

export const SingleOrderDetails = async (orderId) => {
  try {
    const respone = await axios.get(`${URL}/get/order/detail/${orderId}`, {
      headers: headers,
    });
    return respone.data;
  } catch (error) {
    return error;
  }
};

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export const RecentOrderapi = async () => {
  try {
    const respone = await axios.get(`${URL}/Get-Completed-Orders`, {
      headers: headers,
    });
    return respone.data;
  } catch (error) {
    return error;
  }
};
