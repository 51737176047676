import React, { useState, useEffect } from "react";
import { HiMinus } from "react-icons/hi2";
import { FaPlus } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { Toaster, toast } from "react-hot-toast";
import { DeleteCart, UpdateCart } from "../../APIComponents/CartsApi";
import { getCustomerId } from "../../APIComponents/CartsApi";
import { Link, useNavigate } from "react-router-dom";
import "./common.css";
import { useCart } from "../../PopupComponent/CartContext";

export default function CardItems({
  data = {},
  setCartItems,
  subtotal,
  handleCartUpdate,
  closePopup,
  loading 
}) {
  const { updateCartCount } = useCart();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(Number(data.Quantity) || 0);
  const { t } = useTranslation();
  
  const rawPrice = data.productDetails?.productPrice || "0";
  const cleanedPrice = rawPrice.replace(/[^0-9.-]+/g, "");
  const productPrice = parseFloat(cleanedPrice) || 0;
  const totalPrice = (productPrice * quantity).toFixed(2);

  useEffect(() => {
    if (data && data.productDetails) {
      setQuantity(Number(data.Quantity) || 0);
    }
  }, [data]);

  const handleMinusQuantity = async () => {
    if (quantity <= 1) return;
    const newQuantity = quantity - 1;
    const userId = localStorage.getItem('userId');
    const customerId = userId ? userId : getCustomerId();

    try {
      const response = await UpdateCart(
        customerId,
        data.ProductId,
        newQuantity
      );
      if (response && response.status === 200) {
        setQuantity(newQuantity);
        handleCartUpdate(); 
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
      toast.error("Failed to decrease product quantity");
    }
  };

  const handlePlusQuantity = async () => {
    const newQuantity = quantity + 1;
    const userId = localStorage.getItem('userId');
    const customerId = userId ? userId : getCustomerId();

    try {
      const response = await UpdateCart(
        customerId,
        data.ProductId,
        newQuantity
      );
      if (response && response.status === 200) {
        setQuantity(newQuantity);
        handleCartUpdate();
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
      toast.error("Failed to increase product quantity");
    }
  };

  const handleRemoveProduct = async (ProductId, CustomerId) => {
    try {
      const response = await DeleteCart(CustomerId, ProductId);
      if (response && response.status === 200) {
        toast.success("Product is removed from cart");
        setCartItems((prevItems) =>
          prevItems.filter((item) => item.ProductId !== data.ProductId)
        );
        handleCartUpdate();
        updateCartCount();
      }
    } catch (error) {
      console.error("Error removing product from cart:", error);
      toast.error("Error removing product from cart");
    }
  };

  if (loading) {
    return (
      <div className="text-center mt-5">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  const viewProduct = async (productId) => {
    try {
      await closePopup(); 
      navigate(`/viewSingleProduct/${productId}`);
    } catch (error) {
      console.error("Error closing popup or navigating:", error);
    }
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      {data && data.productDetails ? (
        <div className="cart_pro_lists">
          <div className="product-area d-flex gap-3 cart_lists">
            <>
            <div className="product_img_box">
              <img
                src={data.productDetails.productImage}
                alt="Product"
                className="pro-image"
                height="20%"
                width="30%"
                onClick={() => viewProduct(data.ProductId)}
              /></div>
              <div className="pro-content mx-3">
                <h3>{data.productDetails.productTitle}</h3>
                <p className="price-data fs-5">Price: <strong>${totalPrice}</strong></p>
                <div className="pro-quant d-flex">
                  <div className="pro-buttons border">
                    <button className="btn" onClick={handleMinusQuantity}>
                      <HiMinus />
                    </button>
                    <input
                      type="text"
                      className="quantity-input"
                      value={quantity}
                      readOnly
                    />
                    <button className="btn add_qty" onClick={handlePlusQuantity}>
                      <FaPlus />
                    </button>
                  </div>
                  <button
                    className="btn remove-button"
                    onClick={() =>
                      handleRemoveProduct(data.ProductId, data.CustomerId)
                    }
                  >
                    Remove
                  </button>
                </div>
              </div>
            </>
          </div>
          <div className="card-fotter-area">
            <div className="sub-total d-flex justify-content-between">
              <p className="subtotal-content text-start fs-3">
                Subtotal: ${subtotal}
              </p>
            </div>
            <div className="card-foo-btn d-flex justify-content-between">
              <Link to="/viewCart">
                <button
                  className="btn view-out-btn text-start"
                  onClick={closePopup}
                >
                  View Cart
                </button>
              </Link>
              <Link to="/Checkout">
                <button
                  className="btn check-out-btn text-end"
                  onClick={closePopup}
                >
                  Check Out
                </button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="no_data"><span>No product data available.</span></div>
      )}
    </>
  );
}
