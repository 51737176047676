import React, { useEffect, useState } from "react";
import CustomerProfile from "../customerDashboardProfile/CustomerProfile";
import { RecentOrderapi } from "../customerDashboardApi/PostApi";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "./RecentOrder.css";
import { FiLogOut } from "react-icons/fi";
import profileIcon from "../../assets/img/profile-ic.png";

export default function RecentOrder() {
  const [recentOrder, setRecentOrder] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(3);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const userToken = localStorage.getItem("userToken");
  const Navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("userId");
    Navigate("/");
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const fetchRecentOrder = async () => {
      try {
        const response = await RecentOrderapi();
        console.log(response, "recent order");
        setRecentOrder(response.orders || []);
      } catch (error) {
        console.log(error, "error");
      }
    };

    fetchRecentOrder();
  }, []);

  const pageCount = Math.ceil(recentOrder.length / itemsPerPage);

  const handlePageChange = (event) => {
    setCurrentPage(event.selected);
  };

  const displayedOrders = recentOrder.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  return (
    <>
      <CustomerProfile />
      <section className="home">
        <div className="main-header-tag-title">
          <div className="Side-bar-center">Customer Dashboard</div>
          <div className="profile-icon">
            <img
              src={profileIcon}
              className="person-image"
              alt="profile-icon"
              onClick={toggleDropdown}
              style={{ cursor: "pointer" }}
            />
            {isDropdownOpen && userToken && (
              <div className="dropdown-menu">
                <button className="logout-button" onClick={handleLogout}>
                  <FiLogOut />
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
      <table
        className="table table-striped"
        style={{ width: "70%", marginLeft: "20%", marginTop: "10%" }}
      >
        <thead>
          <tr>
            <th scope="col">Product Image</th>
            <th scope="col">Title</th>
            <th scope="col">Status</th>
            <th scope="col">Product ID</th>
            <th scope="col">Product Price</th>
          </tr>
        </thead>
        <tbody>
          {displayedOrders.map((order) =>
            order.productDetails.length > 0 ? (
              <tr key={order._id}>
                <td>
                  <Link to={`/admin/recentOrderDetail?orderId=${order._id}`}>
                    {order.productDetails[0].productInfo.productImage && (
                      <img
                        src={order.productDetails[0].productInfo.productImage}
                        alt={order.productDetails[0].productInfo.productTitle}
                        style={{ height: "100px", width: "50%" }}
                        className="order-img"
                      />
                    )}
                  </Link>
                </td>
                <td>{order.productDetails[0].productInfo.productTitle}</td>
                <td>{order.deliveryStatus}</td>
                <td>{order.productDetails[0].orderedProductId}</td>
                <td>${order.productDetails[0].productInfo.productPrice}</td>
              </tr>
            ) : null
          )}
        </tbody>
      </table>
      <div style={{ marginLeft: "30%" }}>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
}
