import React, { useEffect, useState } from "react";
import CustomerProfile from "./CustomerProfile";
import "./CustomerProfile.css";
import { useLocation } from "react-router-dom";
import { SingleOrderDetails } from "../customerDashboardApi/PostApi";
import Spinner from "react-bootstrap/Spinner";
import Header from "../header/Header";
import { Toaster, toast } from "react-hot-toast";
import { jsPDF } from "jspdf";
import { CancelOrder } from "../../CustomerDashboard/APIComponents/ProductAPIs/GetAPI";

export default function OrderDetails() {
  const location = useLocation();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customerId, setCustomerId] = useState(null);

  const fetchOrderDetails = async () => {
    const queryParams = new URLSearchParams(location.search);
    const orderIdFromUrl = queryParams.get("orderId");
    if (orderIdFromUrl) {
      try {
        const response = await SingleOrderDetails(orderIdFromUrl);
        setOrderDetails(response.order);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [location.search]);

  useEffect(() => {
    const customerId = localStorage.getItem("customerId");
    setCustomerId(customerId);
  }, []);

  const handleCancelOrder = async (orderId, productId) => {
    if (!customerId) {
      toast.error("Customer ID is missing.");
      return;
    }

    try {
      const response = await CancelOrder({ orderId, productId, customerId });
      toast.success(response.data.message);
      fetchOrderDetails();
    } catch (error) {
      toast.error(
        "Error cancelling order: " +
        (error.response?.data?.message || error.message)
      );
    }
  };

  if (loading) {
    return (
      <Spinner animation="border" role="status" className="loading-spinner">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const productDetails = orderDetails?.productDetails || [];

  const subtotal = productDetails.reduce((acc, productDetail) => {
    const productPrice =
      parseFloat(
        productDetail.product?.productPrice.replace(/[^0-9.-]+/g, "")
      ) || 0;
    const quantity = parseInt(productDetail.quantity) || 0;
    return acc + productPrice * quantity;
  }, 0);

  const discount = parseFloat(
    orderDetails?.productDetails[0]?.product?.discount || 0
  );
  const taxPercentage = parseFloat(
    orderDetails?.productDetails[0]?.product?.taxPercentage || 0
  );
  const escFees = parseFloat(
    orderDetails?.productDetails[0]?.product?.escFees || 0
  );

  const taxAmount = subtotal * (taxPercentage / 100);
  const totalAmount = subtotal - discount + taxAmount + escFees;

  const handleGeneratePDF = async () => {
    try {
      if (orderDetails.deliveryStatus !== "Delivered") {
        toast.error("Order is not delivered yet.");
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Gen-Receipt`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderId: orderDetails._id,
          customerName: orderDetails.customerName,
          orderDate: orderDetails.orderDate,
          totalAmount: totalAmount,
          items: productDetails.map((pd) => ({
            name: pd.product?.productTitle,
            quantity: pd.quantity,
            price: parseFloat(
              pd.product?.productPrice.replace(/[^0-9.-]+/g, "")
            ),
          })),
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to generate PDF");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Receipt_${orderDetails._id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error generating receipt:", error);
      toast.error("Error generating receipt.");
    }
  };
  return (
    <>
      <CustomerProfile />
      <Header />
      <Toaster position="top-right" reverseOrder={false} />
      <section className="order-details">
        <div className="detail-box">
          <div className="detail-headdimg">
            <h3>Order Details</h3>
          </div>
          <div className="detail-contant">
            <p>Order Number: {orderDetails?._id}</p>
            <p>
              Order Date:{" "}
              {orderDetails?.orderDate
                ? new Date(orderDetails.orderDate).toLocaleDateString()
                : "N/A"}
            </p>
            <p>Payment Status: {orderDetails?.paymentStatus}</p>
            <p>Delivery Status: {orderDetails?.deliveryStatus}</p>
            <p>Delivery Method: {orderDetails?.deliveryMethod}</p>
          </div>
        </div>
        <div className="product-box">
          {productDetails.map((productDetail, index) => (
            <div className="product-box-container" key={index}>
              <div className="product-detail-top">
                <div className="Product-heading-img-box">
                  <h3>Product</h3>
                  <div className="product-image">
                    <div className="img-product">
                      <img
                        src={productDetail.product?.productImage}
                        alt={productDetail.product?.productTitle}
                      />
                    </div>
                    <p>{productDetail.product?.productDescription}</p>
                  </div>
                </div>
                <div className="Product-heading-img requested">
                  <h3>Requested Shipping Date</h3>
                  <div className="product-dtl">
                    <p>&nbsp;</p>
                  </div>
                </div>
                <div className="Product-heading-img">
                  <h3>Status</h3>
                  <div className="product-dtl">
                    <p>{orderDetails?.deliveryStatus}</p>
                  </div>
                </div>
                <div className="Product-heading-img">
                  <h3>Qty</h3>
                  <div className="product-dtl">
                    <p>{productDetail.quantity}</p>
                  </div>
                </div>
                <div className="Product-heading-img">
                  <h3>Total</h3>
                  <div className="product-dtl">
                    <p>{productDetail.product?.productPrice}</p>
                  </div>
                </div>
                {orderDetails?.deliveryStatus !== "Delivered" &&
                  orderDetails?.deliveryStatus !== "Cancelled" && (
                    <div className="Product-heading-img">
                      <button
                        onClick={() =>
                          handleCancelOrder(
                            orderDetails._id,
                            productDetail.product?._id
                          )
                        }
                      >
                        Cancel Order
                      </button>
                    </div>
                  )}
              </div>
            </div>
          ))}
          <div className="total-box">
            <div className="amount-box-container">
              <div className="amount-box">
                <div className="total-text">
                  <p>Subtotal:</p>
                </div>
                <div className="total-text">
                  <p>${subtotal.toFixed(2)}</p>
                </div>
              </div>
              <div className="amount-box">
                <div className="total-text">
                  <p>Discount:</p>
                </div>
                <div className="total-text">
                  <p>${discount.toFixed(2)}</p>
                </div>
              </div>
              <div className="amount-box">
                <div className="total-text">
                  <p>Tax:</p>
                </div>
                <div className="total-text">
                  <p>${taxAmount.toFixed(2)}</p>
                </div>
              </div>
              <div className="amount-box">
                <div className="total-text">
                  <p>Shipping Tax:</p>
                </div>
                <div className="total-text">
                  <p>${escFees.toFixed(2)}</p>
                </div>
              </div>
              <div className="amount-box">
                <div className="total-text">
                  <p>Total:</p>
                </div>
                <div className="total-text">
                  <h6>${totalAmount.toFixed(2)}</h6>
                </div>
              </div>

              {orderDetails?.deliveryStatus === "Delivered" && (
                <button onClick={() => handleGeneratePDF(true)}>
                  Generate Receipt
                </button>
              )}
            </div>
          </div>
        </div>
        {/* Additional details section (commented out) */}
        {/* <div className="detail-box address">
          <div className="detail-headdimg">
            <h3>Additional Details</h3>
          </div>
          <div className="detail-contant">
            <p>Comment: {orderDetails?.comment}</p>
            <p>Notes: {orderDetails?.notes}</p>
            <p>Customer Name: {orderDetails?.customerName}</p>
            <p>Contact Info: {orderDetails?.contactInfo}</p>
            <p>Shipping Address: {orderDetails?.shippingAddress}</p>
            <p>Billing Address: {orderDetails?.billingAddress}</p>
          </div>
        </div> */}
      </section>
    </>
  );
}
